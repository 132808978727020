.tag-root {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  padding: 3px 12px;
  gap: 4px;
  background-color: var(--thirty-color);
  color: var(--text-color);
  border-radius: 50px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-size: 12px;
  .dot {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--text-color);
  }
  .svg-root {
    height: 15px;
    width: 15px;
  }
  svg {
    .svgFill {
      fill: var(--text-color);
    }
    .svgStroke {
      stroke: var(--text-color);
    }
  }
  &.red {
    background-color: #3f3f46;
    color: #f87171;
    .dot {
      background-color: #f87171;
    }
    svg {
      .svgFill {
        fill: #f87171;
      }
      .svgStroke {
        stroke: #f87171;
      }
    }
  }
  &.green {
    background-color: #3f3f46;
    color: #64ba18;
    .dot {
      background-color: #64ba18;
    }
    .svgFill {
      fill: #64ba18;
    }
    .svgStroke {
      stroke: #64ba18;
    }
  }
  &.orange {
    background-color: #3f3f46;
    color: #f59e0b;
    .dot {
      background-color: #f59e0b;
    }
    .svgFill {
      fill: #f59e0b;
    }
    .svgStroke {
      stroke: #f59e0b;
    }
  }
  &.purple {
    background-color: #3f3f46;
    color: #b379ec;
    .dot {
      background-color: #b379ec;
    }
    .svgFill {
      fill: #b379ec;
    }
    .svgStroke {
      stroke: #b379ec;
    }
  }
  &.yellow {
    background-color: #3f3f46;
    color: #fbbf24;
    .dot {
      background-color: #fbbf24;
    }
    .svgFill {
      fill: #fbbf24;
    }
    .svgStroke {
      stroke: #fbbf24;
    }
  }
  &.blue {
    background-color: #1d4ed8;
    color: #93c5fd;
    .dot {
      background-color: #93c5fd;
    }
    .svgFill {
      fill: #93c5fd;
    }
    .svgStroke {
      stroke: #93c5fd;
    }
  }
  &.grey {
    background-color: #3f3f46;
    color: #e4e4e7;
    .dot {
      background-color: #e4e4e7;
    }
    .svgFill {
      fill: #e4e4e7;
    }
    .svgStroke {
      stroke: #e4e4e7;
    }
  }
  &.light-mode {
    &.red {
      background-color: #fef2f2;
      color: #d9382e;
      .dot {
        background-color: #d9382e;
      }
      .svgFill {
        fill: #d9382e;
      }
      .svgStroke {
        stroke: #d9382e;
      }
    }
    &.green {
      background-color: #f5ffec;
      color: #64ba18;
      .dot {
        background-color: #64ba18;
      }
      .svgFill {
        fill: #64ba18;
      }
      .svgStroke {
        stroke: #64ba18;
      }
    }
    &.orange {
      background-color: #fff8f2;
      color: #ff8412;
      .dot {
        background-color: #ff8412;
      }
      .svgFill {
        fill: #ff8412;
      }
      .svgStroke {
        stroke: #ff8412;
      }
    }
    &.purple {
      background-color: #f9f2ff;
      color: #985cd3;
      .dot {
        background-color: #985cd3;
      }
      .svgFill {
        fill: #985cd3;
      }
      .svgStroke {
        stroke: #985cd3;
      }
    }
    &.yellow {
      background-color: #fffbeb;
      color: #f59e0b;
      .dot {
        background-color: #f59e0b;
      }
      .svgFill {
        fill: #f59e0b;
      }
      .svgStroke {
        stroke: #f59e0b;
      }
    }
    &.blue {
      background-color: #eff6ff;
      color: #2563eb;
      .dot {
        background-color: #2563eb;
      }
      .svgFill {
        fill: #2563eb;
      }
      .svgStroke {
        stroke: #2563eb;
      }
    }
    &.grey {
      background-color: #f4f4f5;
      color: #71717a;
      .dot {
        background-color: #71717a;
      }
      .svgFill {
        fill: #71717a;
      }
      .svgStroke {
        stroke: #71717a;
      }
    }
  }
}

body,
#root,
.navigation-root {
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  position: relative;
  background-color: var(--sixty-color);
  transition: background-color 0.3s, color 0.3s;
  color: var(--text-color);
}
.navigation-root {
  display: flex;
}
.side-bar {
  width: 180px;
}
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .top-bar {
    height: 42px;
    flex-shrink: 0;
    overflow: hidden;
  }
  .content-container {
    flex-grow: 1;
    overflow: hidden;
    padding: 6px;
  }
}

.tokens-root {
  height: 100%;
  .content-header {
    .btn.add {
      margin-left: auto;
    }
  }
  .tokens-container {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-content: flex-start;
    padding: 12px;
    .token-card {
      background-color: var(--thirty-color);
      padding: 12px;
      border-radius: 5px;
      border: 1px solid var(--border-color);
      transition: 0.3s all;
      &:hover {
        border-color: var(--ten-color);
      }
    }
  }
}
.modal {
  .content {
    .modal-body.disable-token-modal {
      max-width: 400px;
      .btn {
        margin-top: 24px;
      }
    }
  }
}

.token-card {
  position: relative;
  .token-label {
    padding-right: 30px;
  }
}
.generated-token {
  max-width: 400px;
  word-break: break-all;
  margin-bottom: 24px;
}
.generated-modal-title {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
}
.generated-token-btn {
  margin-top: 12px;
}
.modal {
  .content {
    .add-new-token-modal-body {
      padding-top: 12px;
      @media screen and (min-height: 700px) {
        overflow: visible;
      }
    }
  }
}

.loading-component {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.3s;
  top: 0;
  left: 0;
  &.loading {
    opacity: 1;
  }
  &.not-visible {
    opacity: 0;
    pointer-events: none;
  }
}

.filter-fields {
  padding: 6px;
  .clear-filters {
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 12px;
  }
  .limit-fields {
    display: flex;
    gap: 6px;
    padding-top: 6px;
    .field {
      border: 1px solid var(--border-color);
      padding: 6px 12px;
      border-radius: 5px;
      transition: all 0.3s;
      cursor: pointer;
      &.selected {
        background-color: var(--ten-color);
      }
    }
  }
}

.top-bar {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: bold;
  padding-left: 22px;
  background-color: var(--grey);
  color: var(--white);
  transition: background-color 0.3s;
}

.pincode-input-container {
  display: flex;
  gap: 6px;
  input {
    padding: 0;
    margin: 0 2px;
    text-align: center;
    border: 1px solid;
    background: transparent;
    width: 50px;
    height: 50px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
.pincode-input-container.custom-style {
}

.update-warning {
  text-align: center;
  background-color: var(--orange);
  color: var(--white);
  cursor: pointer;
  transition: max-height 1s ease-in-out;
  &.open {
    max-height: 100px;
    opacity: 1;
  }
  &.closed {
    max-height: 0;
    opacity: 0;
  }
}

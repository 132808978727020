.date-picker-modal {
  .date-picker-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 3px;
  }
  .date-picker-body {
    padding: 12px;
    border-radius: 5px;
    background-color: var(--white);
  }
}

.data-table-expanded-root {
  padding: 12px;
  .order-items {
    .order-item {
      display: flex;
      gap: 6px;
      .order-item-count {
        width: 60px;
        text-align: right;
      }
      .order-item-name {
        flex-grow: 1;
      }
      .order-item-itemPrice {
        padding-right: 20px;
      }
      .order-item-more {
        width: 75px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

.user-languages {
  display: flex;
  gap: 6px;
  padding: 6px 12px;
  &.small {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .language {
    opacity: 0.5;
    cursor: pointer;
    transition: all 0.3s;
    height: 20px;
    &:hover {
      opacity: 1;
    }
    &.selected {
      opacity: 1;
    }
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.appear {
  animation: 1s appear forwards;
}
.appear-quick {
  animation: 0.2s appear cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

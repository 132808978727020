.providers-root {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 6px;
  .provider-cards-container {
    display: flex;
    align-content: flex-start;
    flex-grow: 1;
    gap: 12px;
    flex-wrap: wrap;
    padding: 12px;
    overflow: auto;
  }
}
.ntak-reg-modal-body {
  width: 600px;
  .tax-number {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 12px;
  }
  .upload-root {
    margin: 6px 0 12px;
  }
  .generate-success-text {
    padding-top: 12px;
  }
  .download-csr-link {
    font-size: 18px;
    font-weight: bold;
    color: var(--ten-color);
    text-decoration: underline;
    cursor: pointer;
  }
}
.ntak-reg-question {
  .text {
    font-size: 18px;
    font-weight: bold;
  }
  .answers {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 12px 0;
    .answer {
      background-color: var(--sixty-color);
      padding: 3px 12px;
      border-radius: 5px;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        background-color: var(--ten-color-80);
        color: var(--white);
      }
      &.selected {
        background-color: var(--ten-color);
        color: var(--white);
      }
    }
  }
}

.error-message-window {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  backdrop-filter: blur(4px) brightness(0.5);
  color: var(--white);
  .error-message-title {
    font-size: 24px;
    font-weight: 600;
  }
  .error-message-actions {
    display: flex;
    gap: 12px;
    .error-message-action {
      background: rgba(0, 0, 0, 0.5);
      padding: 6px 12px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
}

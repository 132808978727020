.switch-root {
  display: inline-flex;
  position: relative;
  align-items: center;
  width: 35px;
  min-width: 35px;
  max-width: 35px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 300px;
  padding: 2px;
  cursor: pointer;
  &.checked {
    background-color: var(--ten-color);
  }
  .switch-inner {
    transition: left 0.4s, background-color 0.4s;
    position: absolute;
    left: 2px;
    width: 14px;
    height: 14px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    top: 50%;
    transform: translateY(-50%);
    &.checked {
      left: 19px;
      background-color: var(--white);
    }
  }
}

.float {
  width: 100%;
  height: 100%;
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  .mask {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
  }
  .content {
    width: max-content;
    background-color: var(--thirty-color);
    color: var(--text-color);
    padding: 6px;
    border-radius: 5px;
    z-index: 10;
    filter: drop-shadow(8px 0px 11px rgba(0, 0, 0, 0.25));
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
}

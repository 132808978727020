.content-header,
.filters-container {
  display: flex;
  align-items: center;
  height: 70px;
  gap: 12px;
  margin-bottom: 6px;
  position: relative;
  z-index: 2;
}
.filters-container {
  overflow: auto;
  flex-shrink: 0;
}

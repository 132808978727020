.sso-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}

.side-bar {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10;
  background-color: var(--grey);
  transition: background-color 0.3s;
  color: var(--white);
  &.open {
    width: 180px;
    transition: width 0.3s;
    .side-bar-toggler {
      top: 27px;
      right: -16px;
    }
    .side-bar-menu {
      padding-top: 0px;
      .menu-text {
        max-width: 9999px;
        min-width: max-content;
      }
    }
  }
  &.closed {
    width: 45px;
    transition: width 0.3s;
    .side-bar-toggler {
      top: 48px;
      right: 6px;
      filter: none;
      .svg-root {
        width: 33px;
        height: 33px;
        border-radius: 5px;
        background-color: var(--darkGrey);
        .svgFill {
          fill: var(--white);
        }
        .svgStroke {
          stroke: var(--white);
        }
      }
    }
    .side-bar-logo {
      font-size: 12px;
    }
    .side-bar-profile {
      max-height: 0px;
    }
    .side-bar-menu {
      .menu-text {
        max-width: 0;
      }
    }
  }
  .side-bar-toggler {
    position: absolute;
    filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    transition: all 0.3s;
    z-index: 10;
    &:hover {
      filter: brightness(1.1);
    }
    .svg-root {
      transition: all 0.3s;
      height: 36px;
      width: 36px;
      background-color: var(--sixty-color);
      padding: 5px;
      border-radius: 100%;
    }
  }
  .side-bar-logo {
    height: 42px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    text-align: center;
    transition: font-size 0.3s;
  }
  .side-bar-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 36px 6px 0;
    transition: padding 0.3s;
  }
  .menu-element {
    display: flex;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    background-color: var(--darkGrey);
    border-radius: 5px;
    transition: all 0.3s;
    gap: 6px;
    .svg-root {
      .svgStroke {
        stroke: var(--white);
      }
      .svgFill {
        fill: var(--white);
      }
    }
    &.selected {
      background-color: var(--ten-color);
      color: var(--white);
    }
    .menu-text {
      overflow: hidden;
    }
    .svg-root {
      flex-shrink: 0;
    }
    &:hover {
      filter: brightness(1.1);
    }
  }
  .side-bottom-container {
    .logout-container {
      padding: 6px;
    }
    .version {
      font-size: 10px;
      opacity: 0.5;
      padding-left: 3px;
    }
  }
}

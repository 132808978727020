.alert {
  position: relative;
  padding: 6px 12px;
  border-left: solid 3px;
  .title,
  .desc,
  .additional-content {
    position: relative;
    z-index: 2;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .background {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: 1;
  }
  &.orange {
    border-left-color: #ff8412;
    .background {
      background-color: #ff8412;
    }
  }
  &.red {
    border-left-color: #d9382e;
    .background {
      background-color: #d9382e;
    }
  }
  &.blue {
    border-left-color: #0c85d5;
    .background {
      background-color: #0c85d5;
    }
  }
  &.green {
    border-left-color: #64ba18;
    .background {
      background-color: #64ba18;
    }
  }
  &.teal {
    border-left-color: #19a29f;
    .background {
      background-color: #19a29f;
    }
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px) brightness(0.7);
  color: var(--white);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px;
}

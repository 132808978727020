.stats-content {
  padding: 12px;
  .label {
    font-size: 12px;
  }
  .value {
    font-size: 16px;
    margin-bottom: 6px;
  }
}

.svg-root {
  width: 20px;
  height: 20px;
  display: inline-flex;
  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
    .svgStroke {
      stroke: var(--text-color);
    }
    .svgFill {
      fill: var(--text-color);
    }
  }
}

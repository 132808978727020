.orders-root {
  height: 100%;
  .content-header {
    .btn.add {
      margin-left: auto;
    }
  }
}
.modal {
  .content {
    .add-new-order-modal-body {
      padding-top: 12px;
      background-color: transparent;
      padding: 6px;
      overflow: visible;
      .col {
        flex-shrink: 0;
        max-width: 100%;
        border-radius: 5px;
      }
      .row {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--thirty-color);
        min-height: 400px;
        border: 1px solid var(--border-color);
        border-top: none;
        overflow: auto;
        @media screen and (min-width: 700px) {
          overflow: visible;
        }
      }
      .title {
        font-size: 18px;
      }
      .sub-title {
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .order-data,
    .order-summary,
    .order-payments {
      padding: 12px;
    }
    .data-block {
      margin-bottom: 6px;
      padding-left: 6px;
      border-left: 3px solid var(--green);
      background-color: var(--sixty-color);
      &.error {
        border-color: var(--red);
      }
      .label {
        font-size: 12px;
        opacity: 0.7;
      }
    }
    .payments {
      padding: 0 12px 12px;
      .total-payments {
        margin: 6px;
        display: flex;
        .value {
          flex-grow: 1;
          padding-right: 31px;
          text-align: right;
        }
      }
      .add-payment {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
        align-items: center;
        .input-select {
          min-width: 140px;
        }
        .payment-value {
          width: 100px;
        }
      }
      .order-payments {
        padding: 12px 0;
        max-height: 310px;
        overflow: auto;
        .no-added-payment {
          padding: 12px;
        }
      }
      .payment {
        display: flex;
        gap: 6px;
        margin: 6px 6px 0;
        padding: 6px;
        border-radius: 5px;
        background-color: var(--sixty-color);
        .payment-type {
          flex-grow: 1;
        }
        .svg-root {
          cursor: pointer;
        }
      }
    }
    .add-item {
      max-width: 420px;
      min-height: 350px;
      padding: 12px;
      .fields {
        display: flex;
        gap: 6px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        align-content: flex-start;
      }
      .btn {
        margin: 12px auto 0;
      }
      .input-select {
        min-width: 180px;
        max-width: 180px;
        margin-left: 6px;
      }
    }
    .order-items {
      display: flex;
      flex-wrap: wrap;
      .added-order-items {
        padding: 12px;
        .footer {
          display: flex;
          margin-top: 6px;
          padding-top: 6px;
          border-top: 1px solid var(--border-color);
          gap: 12px;
          .label {
            flex-shrink: 0;
          }
          .value {
            padding-right: 26px;
            flex-grow: 1;
            text-align: right;
          }
        }
      }
      .order-item {
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 6px;
        &:last-child {
          border-bottom: none;
          margin-bottom: 0;
        }
        .item-header {
          display: flex;
          gap: 6px;
          align-items: center;
          .svg-root {
            cursor: pointer;
            transition: all 0.3s;
            border-radius: 5px;
            &:hover {
              background-color: var(--sixty-color);
            }
          }
          .chevron {
            width: 26px;
            height: 26px;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            &.open {
              transform: rotate(90deg);
            }
          }
          .item-price {
            flex-grow: 1;
            text-align: right;
          }
        }
        .item-details {
          padding: 0 0 3px 20px;
          .label {
            font-size: 12px;
            opacity: 0.7;
          }
          .value {
            padding-left: 6px;
          }
        }
      }
    }
  }
}

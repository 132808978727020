input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-color);
  transition: all 0.3s;
  padding: 6px;
  margin: 5px;
  &:focus-visible {
    outline: none;
    border-bottom: 1px solid var(--ten-color);
  }
  &:disabled {
    opacity: 0.5;
  }
}
.input-label {
  margin-top: 12px;
  font-size: 12px;
}

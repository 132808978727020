.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  backdrop-filter: blur(2px) brightness(0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 2;
    background-color: var(--sixty-color);
    padding: 16px;
    border-radius: 5px;
    max-height: 100vh;
    max-width: 100vw;
    .modal-header {
      display: flex;
      gap: 12px;
      justify-content: space-between;
      .modal-title {
        font-size: 18px;
        font-weight: 600;
      }
      .modal-close {
        cursor: pointer;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-radius: 5px;
      margin: 12px 0;
      background-color: var(--thirty-color);
      max-height: calc(100vh - 130px);
      max-width: 100%;
      overflow: auto;
      .sub-title {
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .modal-footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
  }
}

.users-root {
  height: 100%;
  .content-header {
    .btn.add {
      margin-left: auto;
    }
  }
  .ntak-user-enabled-icon {
    &.enabled {
      .svg-root {
        height: 12px;
        .svgStroke {
          stroke: var(--green);
        }
        .svgFill {
          fill: var(--green);
        }
      }
    }
    &.disabled {
      .svg-root {
        .svgStroke {
          stroke: var(--red);
        }
        .svgFill {
          fill: var(--red);
        }
      }
    }
  }
}
.modal {
  .content {
    .add-new-ntakUser-modal-body {
      padding-top: 12px;
      @media screen and (min-height: 700px) {
        overflow: visible;
      }
      .email-check {
        display: flex;
        gap: 12px;
        align-items: center;
        margin-top: 12px;
        .text {
          font-size: 12px;
          text-decoration: underline;
          cursor: pointer;
          padding-left: 6px;
        }
      }
    }
  }
}

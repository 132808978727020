.light-mode-switch-root {
  display: flex;
  align-items: center;
  padding: 6px;
  transition: all 0.3s;
  overflow: hidden;
  &.side-bar-open {
    max-width: 9999px;
  }
  &.side-bar-closed {
    max-width: 45px;
    justify-content: center;
  }
  .switch-icon {
    background-color: var(--sixty-color);
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
  }
  .svg-root {
    cursor: pointer;
  }
  .light-mode-switch-icon {
    height: 20px;
  }
  .light-mode-switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: var(--darkGrey);
    padding: 6px;
    border-radius: 5px;
  }
}

.btn {
  display: inline-flex;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: all 0.3s;
  background-color: var(--thirty-color);
  max-height: 38px;
  overflow: hidden;
  min-width: max-content;
  margin: 5px;
  border: 1px solid var(--border-color);
  &:hover {
    filter: brightness(1.2);
    border-color: var(--ten-color);
  }
  &.disabled {
    transition: all 0.3s;
    filter: grayscale(100%);
    cursor: not-allowed;
    &:hover {
      filter: brightness(1.2) grayscale(100%);
    }
  }
}
.btn-primary {
  background-color: var(--ten-color);
  color: var(--white);
}
.btn-secondary {
  background-color: var(--text-color);
  color: var(--thirty-color);
}
.toggle-btn {
  border: 2px solid var(--ten-color);
  &:hover {
    box-shadow: 0px 0px 10px 0px var(--ten-color);
  }
  &.selected {
    color: var(--white);
    background-color: var(--ten-color);
    box-shadow: 0px 0px 10px 0px var(--ten-color);
  }
}
.icon-btn {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  gap: 3px;
}
.icon-only-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 5px;
  transition: all 0.3s;
  border: 1px solid var(--border-color);
  background-color: var(--thirty-color);
  &.active {
    background-color: var(--ten-color);
    border-color: var(--border-color);
  }
  &:hover {
    border-color: var(--ten-color);
    filter: brightness(1.2);
  }
}
.btn.save {
  background-color: var(--green);
  color: var(--white);
  svg {
    .svgFill {
      fill: var(--white);
    }
    .svgStroke {
      stroke: var(--white);
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px var(--green);
    border-color: var(--green);
  }
  &.disabled:hover {
    box-shadow: none;
  }
}
.btn.cancel {
  background-color: var(--red);
  color: var(--white);
  svg {
    .svgFill {
      fill: var(--white);
    }
    .svgStroke {
      stroke: var(--white);
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px var(--red);
    border-color: var(--red);
  }
  &.disabled:hover {
    box-shadow: none;
  }
}
.btn.add {
  background-color: var(--blue);
  color: var(--white);
  .svg-root {
    svg {
      .svgFill {
        fill: var(--white);
      }
      .svgStroke {
        stroke: var(--white);
      }
    }
  }
  &:hover {
    box-shadow: 0px 0px 10px 0px var(--blue);
  }
  &.disabled:hover {
    box-shadow: none;
  }
}

.tabs {
  display: flex;
  background-color: var(--sixty-color);
  //padding: 8px 8px 0 8px;
  border-bottom: 1px solid var(--border-color);
  border: 1px solid var(--border-color);
  .tab {
    padding: 6px 12px;
    cursor: pointer;
    transition: all 0.3s;
    border-right: 1px solid var(--border-color);
    background-color: var(--sixty-color);
    &:hover {
      background-color: var(--thirty-color);
    }
    &:last-child {
      border-right: none;
    }
    &.active {
      border-top: 1px solid var(--ten-color);
      background-color: var(--thirty-color);
    }
  }
}

.side-bar-profile {
  overflow: hidden;
  transition: height 0.3s;
  .user-name,
  .user-email {
    padding: 0 6px;
    font-size: 12px;
  }
  .user-roles {
    display: flex;
    flex-wrap: wrap;
    padding: 6px;
    .user-role {
      font-size: 10px;
      background-color: var(--ten-color);
      color: var(--white);
      padding: 3px 6px;
      border-radius: 50px;
    }
  }
}

.local-menu {
  .local-menu-icon {
    position: absolute;
    right: 6px;
    top: 6px;
    cursor: pointer;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    transition: background-color 0.3s;
    &:hover,
    &.active {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
.local-menu-btn {
  background-color: var(--sixty-color);
  padding: 3px 12px;
  border-radius: 5px;
  transition: all 0.3s;
  cursor: pointer;
  margin-top: 6px;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background-color: var(--ten-color-80);
    color: var(--white);
  }
}

.modal {
  .content {
    .modal-body.day-modal {
    }
  }
}
.day-box {
  .day-name {
    font-size: 16px;
    font-weight: bold;
  }
  .time-inputs {
    display: flex;
    padding: 0 12px;
    .day-closed {
      .btn {
        height: 38px;
        width: 38px;
      }
    }
  }
}

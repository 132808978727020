.upload-root {
  .dropzone {
    &.is-active {
      .upload-container {
        border-color: var(--green);
      }
    }
  }
  .upload-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 12px;
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: max-content;
    transition: all 0.3s;
    .svg-root {
      width: 40px;
      height: 40px;
      .svgFill {
        fill: var(--ten-color);
      }
      .svgStroke {
        stroke: var(--ten-color);
      }
    }
    .text-or {
      font-size: 10px;
    }
    .text-supported {
      font-size: 12px;
    }
    .upload-button {
      background-color: var(--ten-color);
      color: var(--white);
      padding: 3px 12px;
      border-radius: 100px;
      cursor: pointer;
      transition: all 0.3s;
      margin: 6px;
      &:hover {
        background-color: var(--ten-color-80);
      }
    }
    .text-file-name {
      max-width: 200px;
      overflow: hidden;
    }
  }
}

.provider-card {
  width: 230px;
  background-color: var(--thirty-color);
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 12px;
  transition: all 0.3s;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:hover {
    filter: brightness(1.3);
  }
  .provider-card-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .provider-data {
    display: flex;
    flex-direction: column;
    gap: 6px;
    .provider-data-item {
      .provider-data-item-label {
        font-size: 12px;
        opacity: 0.7;
      }
    }
  }
  .provider-card-tags {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin: 6px 0;
    cursor: pointer;
  }
  &.selected {
    border: 1px solid var(--ten-color);
  }
}

.modal {
  .content {
    .add-new-workday-modal-body {
      padding: 12px 24px 24px 24px;
      .sub-title {
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

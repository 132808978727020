/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
body {
  padding: 0;
  margin: 0;
}
/**
   * Make all elements from the DOM inherit from the parent box-sizing
   * Since `*` has a specificity of 0, it does not override the `html` value
   * making all elements inheriting from the root box-sizing value
   * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   */
*,
*::before,
*::after {
  box-sizing: inherit;
}
:root {
  background-color: #1f2023;
  --white: #eceff1;
  --green: #43a047;
  --yellow: #f1c40f;
  --red: #c62828;
  --orange: #ff8412;
  --blue: #0c85d5;
  --darkGrey: #1f2023;
  --grey: #27282c;
}
/**
   * Clear inner floats
   */
.clearfix::after {
  clear: both;
  content: "";
  display: table;
}
table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}
.hidden {
  opacity: 0;
}
textarea:focus,
input:focus {
  outline: none;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"] {
  border: 1px solid var(--border-color);
  padding: 7px 12px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--text-color);
  background-color: var(--thirty-color);
  font-family: Poppins;
  position: relative;
  &:hover {
    border-color: var(--ten-color);
  }
}
::-webkit-calendar-picker-indicator {
  background: rgba(0, 0, 0, 0);
  bottom: 0;
  color: rgba(0, 0, 0, 0);
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
a {
  color: var(--ten-color);
  cursor: pointer;
}
.mb-1 {
  margin-bottom: 12px;
}
